<template>
  <b-form @submit.prevent="clearImages">
    <div class="title-actions">
      <h5 class="title">Borrar Imágenes</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label for="tournament">Selecciona un torneo</label>
        <select
          name="tournament"
          data-vv-as="torneo"
          id="tournament"
          class="form-control"
          v-model="form.tournament_id"
          v-validate="'required'"
          :state="validateState('tournament')"
        >
          <option v-for="t in tournaments" :value="t.id" :key="t.id">
            {{ t.name }}
          </option>
        </select>
        <span class="error-inputs">{{ errors.first("tournament") }}</span>
      </div>
      <div class="form-group col-12 col-md-6">
        <label for="year">Selecciona el año</label>
        <select
          name="year"
          id="year"
          class="form-control"
          v-model="form.year"
          v-validate="'required'"
          :state="validateState('year')"
          data-vv-as="año"
        >
          <option v-for="y in years" :value="y" :key="y">{{ y }}</option>
        </select>
        <span class="error-inputs">{{ errors.first("year") }}</span>
      </div>
    </div>
    <b-button type="submit" variant="primary" :disabled="loading"
      >LIMPIAR</b-button
    >
    <b-button class="ml-3" @click="$router.go(-1)">{{ $t("return") }}</b-button>
  </b-form>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ClearImages",
  data() {
    return {
      form: {},
      months: [],
      years: [],
      tournaments: [],
    };
  },
  computed: {
    ...mapGetters(["loading", "lang"]),
  },
  mounted() {
    this.changeTitleBar("Borrar imagenes");
    this.months = Array.from({ length: 12 }, (n, i) => {
      const month = i.toString().padStart(2, "0");
      return month;
    });
    const date = new Date();
    const currentYear = date.getFullYear();
    for (let i = 2016; i <= currentYear; i++) {
      this.years.push(i);
    }
    this.fetchTournaments();
  },
  methods: {
    fetchTournaments() {
      const path = `auth/${this.lang}/${this.$route.params.console}/super/clear-images/tournaments`;
      this.$axios.get(path).then((response) => {
        this.tournaments = response.data.data;
      });
    },
    clearImages() {
      this.$validator.validate().then((result) => {
        if (result) {
          const path = `auth/${this.lang}/${this.$route.params.console}/super/clear-images`;
          this.$axios.post(path, this.form).then((response) => {
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS);
          });
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      });
    },
  },
};
</script>
